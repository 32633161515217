<template>
  <div>
    <v-data-table :items="animals" :headers="headers" sort-by="sex" :items-per-page="-1">
      <template v-slot:[`item.select`]="{ item }">
        <v-checkbox :value="isSelected(item.animalId)" @change="onSelect(item.animalId)" hide-details />
      </template>
      <template v-slot:top>
        <v-toolbar>
          <v-btn :disabled="!actionsEnabled" @click="showShippingDialog = true">{{
            $t('butcher.slaughter.shipToSlaughterHouse') }}</v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
    <ShipToSlaughterDialog :animalsSelected="animalsSelected" :show="showShippingDialog"
      @input="showShippingDialog = $event" @reload="loadAnimals()" />
  </div>
</template>

<script>
import ShipToSlaughterDialog from './ShipToSlaughterDialog.vue'

export default {
  name: 'ButcherSlaughter',
  data: () => ({
    animals: [],
    headers: [
      { text: 'Select', value: 'select', sortable: false },
      { text: 'ID', align: 'start', value: 'externalId' },
      { text: 'Sex', align: 'start', value: 'sex' },
      { text: 'Type', align: 'start', value: 'type' }
    ],
    actionsEnabled: false,
    showShippingDialog: false,
    animalsSelected: []
  }),

  computed: {
    isSelected () {
      return (animalId) => {
        return this.animalsSelected.indexOf(animalId) !== -1
      }
    }
  },

  beforeMount () {
    this.loadAnimals()
  },

  methods: {
    onSelect (animalId) {
      const index = this.animalsSelected.indexOf(animalId)
      if (index === -1) {
        // The animal is not in the array, so add it
        this.animalsSelected.push(animalId)
      } else {
        // The animal is in the array, so remove it
        this.animalsSelected.splice(index, 1)
      }

      this.actionsEnabled = true
    },

    loadAnimals () {
      fetch('/api/animals/sale-ready', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((responseAnimals) => responseAnimals.json())
        .then((dataAnimals) => {
          this.actionsEnabled = false
          this.animalsSelected.splice(0, this.animalsSelected.length)
          this.animals = dataAnimals.animals
        })
    }
  },
  components: { ShipToSlaughterDialog }
}
</script>
