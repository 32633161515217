<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title>{{ $t('sendToSlaughterDialog.title') }}</v-card-title>
      <v-card-text>
        {{ $t('sendToSlaughterDialog.prompt') }}
      </v-card-text>
      <v-card-text>
        <v-alert v-if="error" type="error">{{ error }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="closeDialog" color="secondary" text @click="showDialog = false">{{ $t('terms.cancel')
        }}</v-btn>
        <v-btn data-cy="performAction" color="primary" @click="confirmSendForSlaugther">{{ $t('terms.ok')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SendToSlaughterDialog',
  props: {
    animalsSelected: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    error: null
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  methods: {
    confirmSendForSlaugther () {
      fetch('/api/animals/send-to-slaughter', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.animalsSelected)
      })
        .then(response => {
          if (response.ok) {
            this.$emit('reload')
            this.showDialog = false
          } else {
            this.error = response.statusText
          }
        })
    }
  }

}
</script>
